import React from "react"
import styled from "styled-components"
import BreakpointUp from "../Media/BreakpointUp"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BreakpointDown from "../Media/BreakpointDown"

const Card = styled.div`  
  position: relative;
  margin-bottom:40px;
  padding:0 30px;
  ${BreakpointUp.xxl`
    padding:0 50px;
  `}
  ${BreakpointDown.sm`
   padding:0 15px;
   margin-bottom:15px;
   &:after{ 
     content:'';
     display:block;
     border-bottom:2px dashed #033366;
     height:2px;
     margin-top:30px;
     margin-bottom:40px;
     margin-left: -30px;
     margin-right: -30px;
   }
  `}
  &:before{
    content: "";
    display: block;
    background-image: linear-gradient(to top,transparent 50%,#033366 0%);
    background-position: bottom;
    background-size: 1px 9px;
    background-repeat: repeat-y;
    width: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    ${BreakpointDown.sm`
     display:none; 
    `}
  }
`
const CardBody = styled.div`
  position: relative;
`
const CardLead = styled.div`
  color:#444;
  font-weight:500;
  font-style:italic;
  font-size:18px;
  line-height:30px;
  margin-bottom:15px;
  @media (min-width: 1600px) {
    font-size:20px;
    line-height:32px;
    margin-bottom:20px;
  }
  ${BreakpointDown.sm`
     font-size:16px;  
  `}
`
const CardDesc = styled.div` 
  p{
    margin-bottom:20px;
    color:#666;
    @media (min-width: 1600px) {
      font-size:18px;
      line-height:34px;
    }  
  }
`

const UserProfile = styled.div`
  display:flex;
  flex-wrap: wrap;
  margin:0 -7px 20px;
`
const UserThumb = styled.div` 
  padding:0 7px;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  .gatsby-image-wrapper{
    width:60px;
    height:60px;
    overflow:hidden;
    border-radius:50%;
  }
`
const UserInfo = styled.div`
  padding:0 7px;
  strong{
    display:block;
    font-weight:500;
    font-size:20px;
    line-height: 28px;
  }
  span{
    font-size:14px;
    line-height:28px;        
  }
`
const PostedDate = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #666;
  text-align: right;
  position: absolute;
  right: 0;
  padding: 5px 15px;
  line-height: 20px;
  background: #F0F8FF;
  top: 10px;
  ${BreakpointUp.sm`
    top: 20px;
  `}
  ${BreakpointDown.sm`
    display:none;
  `}
`


const ReviewCard = ({ data }) => {
  const avatar = getImage(data.authorImage)
  return (
    <Card className="card review-card">
      <CardBody className="card-body">
        <PostedDate className="date">{data.date}</PostedDate>
        <UserProfile>
          <UserThumb>
            <GatsbyImage image={avatar} alt={data.author} />
          </UserThumb>
          <UserInfo><strong>{data.author}</strong><span>{data.authorRole}</span></UserInfo>    
        </UserProfile>
        <CardLead>{data.title}</CardLead>
        <CardDesc className="card-desc">
          <div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
        </CardDesc>
      </CardBody>
    </Card>
  )
}

export default ReviewCard
