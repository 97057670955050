import React from "react"
import styled from "styled-components"
import Layout from "../layouts"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import {
  BreadCrumb,
  Container,
  Section,
  SectionDescription,
  SectionPageTitle,
} from "../components/Section"
import ReviewCard from "../components/ReviewCard"
import BreakpointUp from "../components/Media/BreakpointUp"

const CardColumns = styled.div`
  column-count: 1;
  ${BreakpointUp.md` 
        column-count: 2;
    `}
  ${BreakpointUp.xl` 
        column-count: 3;
    `}
`
const CardColumnsCol = styled.div`
  break-inside: avoid;
  display: inline-block;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  .card {
    position: relative;
    margin-bottom: 24px;
    border-radius: 6px;
    overflow: hidden;
    padding: 0;
    ${BreakpointUp.lg`
            padding:0;
        `}
    &:before {
      display: none;
    }
    .card-body {
      background-color: #fff;
      box-shadow: 0px 0 50px 0px rgb(0 75 155 / 5%);
      padding: 30px 30px 30px;
      margin: 0;
      ${BreakpointUp.md`
                padding:60px 30px 30px;
                margin: 15px;
            `}
      ${BreakpointUp.xxl`
                padding:60px 80px;
            `}
    }
  }
`
const ReviewsPage = ({ location, data }) => {
  const allReviews = data.allContentfulReview.edges
  return (
    <Layout isHeader={true} location={location}>
      <Seo title="Customer’s Reviews" />
      <BreadCrumb>
        <Container maxWidth="100%" pl="3%" pr="3%">
          <Link to="/">Home </Link>
          <span>Customer’s Reviews</span>
        </Container>
      </BreadCrumb>
      <Section
        xpt="170px"
        mpt="150px"
        pt="110px"
        xpb="100px"
        mpb="60px"
        pb="30px"
        bgColor="#F0F8FF"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionPageTitle mb="10px"> Customer’s Reviews</SectionPageTitle>
          <SectionDescription maxWidth="1496px" mb="40px">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic.
            </p>
          </SectionDescription>
          <CardColumns>
            {allReviews.map((item, i) => (
              <CardColumnsCol key={i}>
                <ReviewCard data={item.node} />
              </CardColumnsCol>
            ))}
          </CardColumns>
        </Container>
      </Section>
    </Layout>
  )
}

export default ReviewsPage

export const query = graphql`
  query ReviewsPageQuery {
    allContentfulReview {
      edges {
        node {
          title
          author
          authorRole
          authorImage {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          date(fromNow: true)
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
